.gabaritListTemplate {
  --color_overflow: var(--color-white);
  --EM-menu-item-fg-active: var(--color_gabaritList-accent);
  --EM-menu-item-fg-hover: var(--color_gabaritList-accent);
  --EM-activeItem-border-bottom-height: 0.125rem;
  --EM-horizontal-overflow-color: var(--color_overflow-light);
  --EM-horizontal-overflow-color-transparent: var(--color_white-0);
  --EM-menu-item-fg: var(--color_gabaritList-item-light-fg);
  --EM-dropdown-bg: var(--color_gabaritList-dropdown-light-bg);
  --EM-dropdown-item-bg-hover: var(--color_gabaritList-dropdown-light-bg-hover);
  --EM-dropdown-item-fg-hover: var(--color_gabaritList-dropdown-light-fg-hover);

  [data-theme~='dark'] & {
    --color_overflow: var(--color-dark);
    --EM-horizontal-overflow-color: var(--color_overflow-dark);
    --EM-horizontal-overflow-color-transparent: var(
      --od-color_darkmode-primary-0
    );
    --EM-menu-item-fg: var(--color_gabaritList-item-dark-fg);
    --EM-dropdown-bg: var(--color_gabaritList-dropdown-dark-bg);
    --EM-dropdown-item-bg-hover: var(
      --color_gabaritList-dropdown-dark-bg-hover
    );
    --EM-dropdown-item-fg-hover: var(
      --color_gabaritList-dropdown-dark-fg-hover
    );
  }

  position: relative;
  z-index: 6; /* Navigation dropdown must be above the cards (and close button for perso cards) */

  &__tabPanel {
    padding-top: 1px; /* Prevents margin-collpasing between Header and Panel */

    &--hidden {
      display: none;
    }
  }

  &__header {
    --padding-for-tv: var(--dt-spacing-375) var(--dt-spacing-1400)
      var(--dt-spacing-250);
    --padding-for-large-desktop: var(--dt-spacing-375) var(--dt-spacing-750)
      var(--dt-spacing-250);
    --padding-for-desktop: var(--dt-spacing-375) var(--dt-spacing-750)
      var(--dt-spacing-250);
    --padding-for-tablet: var(--dt-spacing-375) var(--dt-spacing-375)
      var(--dt-spacing-250);
    --padding-for-small: var(--dt-spacing-375) 0.9375rem var(--dt-spacing-250);

    &--mainMenu {
      --padding-for-tv: var(--dt-spacing-200) var(--dt-spacing-1400)
        var(--dt-spacing-200);
      --padding-for-large-desktop: var(--dt-spacing-300) var(--dt-spacing-800)
        var(--dt-spacing-200);
      --padding-for-desktop: var(--dt-spacing-300) var(--dt-spacing-800)
        var(--dt-spacing-200);
      --padding-for-tablet: var(--dt-spacing-300) var(--dt-spacing-400)
        var(--dt-spacing-200);
      --padding-for-small: var(--dt-spacing-200) var(--dt-spacing-200)
        var(--dt-spacing-150);
    }

    &--subMenu {
      --padding-for-tv: var(--dt-spacing-200) var(--dt-spacing-1400)
        var(--dt-spacing-200);
      --padding-for-large-desktop: var(--dt-spacing-125) var(--dt-spacing-800);
      --padding-for-desktop: var(--dt-spacing-125) var(--dt-spacing-800);
      --padding-for-tablet: var(--dt-spacing-125) var(--dt-spacing-400);
      --padding-for-small: var(--dt-spacing-125) var(--dt-spacing-200)
        var(--dt-spacing-150);

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      @mixin tvDevice {
        @mixin paddingPages var(--padding-for-tv), var(--padding-for-desktop),
          var(--padding-for-tablet), var(--padding-for-small);
      }
    }

    @mixin paddingPages var(--padding-for-large-desktop),
      var(--padding-for-desktop), var(--padding-for-tablet),
      var(--padding-for-small);
    @mixin tvDevice {
      @mixin paddingPages var(--padding-for-tv), var(--padding-for-desktop),
        var(--padding-for-tablet), var(--padding-for-small);
    }
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;

    @media (--mq-from-tablet) {
      flex-direction: row;
    }

    &--action {
      padding: 0;
      margin-right: -0.125rem;
      margin-left: -0.125rem;

      @media (--mq-from-laptop) {
        padding-bottom: 0.625rem;
      }
    }

    &--multiline {
      position: relative;
      display: block;

      img {
        max-width: 6.25rem;
      }
    }
  }
}

.expandableMenuTheme {
  --horizontal-offset: 0.9375rem;
  width: calc(100% + var(--horizontal-offset) * 2);
  margin-left: calc(
    -1 * var(--horizontal-offset)
  ); /* Correct vertical alignment with sibling elements */
  font-size: 1.125rem;

  @mixin tvDevice {
    margin-left: 0;
    --EM-menu-item-fg-active: var(--color_white);
    --EM-menu-item-fg: var(--color_base);
    --EM-activeItem-border-bottom-height: 0;
  }

  &--mainMenu {
    --EM-activeItem-border-bottom-bottom: var(--dt-spacing-75);
    --EM-activeItem-border-bg: var(--color_secondary);
    --EM-menu-item-text-transform: uppercase;
    --EM-menu-item-font-family: var(--dt-font-family-brand);
    --EM-menu-item-min-height: var(--dt-size-48);
    --EM-menu-item-padding: var(--dt-spacing-none) var(--dt-spacing-100)
      var(--dt-spacing-none);
    --EM-menu-item-tablet-padding: var(--EM-menu-item-padding);
    --EM-menu-item-large-padding: var(--EM-menu-item-padding);
    --EM-menu-item-fg: var(--color_white-70);
    --EM-menu-item-fg-active: var(--color_white);
    --EM-menu-item-fg-hover: var(--color_secondary);
    --EM-menu-item-font-size: var(--dt-font-size-16);

    @media (--mq-from-tablet) {
      --EM-menu-item-font-size: var(--dt-font-size-18);
    }

    @mixin tvDevice {
      --EM-activeItem-border-bottom-height: 0.25rem;
      --EM-activeItem-border-bottom-bottom: 0;
      --EM-menu-item-border-bottom-active-height: 0.25rem;
      --EM-menu-item-border-bottom-active-bottom: 0;
      --EM-menu-item-font-size: var(--dt-font-size-28);
    }

    [data-theme~='telecomitalia'] & {
      --EM-activeItem-border-bg: var(--color_secondary);
      --EM-menu-item-fg-hover: var(--color_secondary);
    }
  }

  &--subMenu {
    --EM-activeItem-bg: var(--color_white-10);
    --EM-aciveItem-border-radius: var(--border-radius-large);
    --EM-activeItem-border-bottom-height: 0;
    --EM-menu-item-font-weight: var(--dt-font-weight-regular);
    --EM-menu-item-padding: var(--dt-spacing-75) var(--dt-spacing-150);
    --EM-menu-item-large-padding: var(--dt-spacing-75) var(--dt-spacing-200);
    --EM-menu-item-tablet-padding: var(--EM-menu-item-large-padding);
    --EM-menu-item-fg: var(--color_white-80);
    --EM-menu-item-fg-active: var(--color_white);

    @media (--mq-from-tablet) {
      --EM-menu-item-font-size: var(--dt-font-size-18);
    }

    @mixin tvDevice {
      --EM-activeItem-border-radius: var(--border-radius-extraLarge);
      --EM-menu-item-font-size: var(--dt-font-size-30);
    }
  }
}
